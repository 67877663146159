export const attorneys = {
  slug: "attorneys",
  image: "/img/2024/Group_web.jpg",
  imageYAlign: 0,
  imageAlignment: "Center Center",
  noRiseForm: true,
  smallTiles: true,
  imgAlt: "Negem and Worthington standing for a picture",
  title: "Attorneys",
  link: "/attorneys",
  form: "DiscussYourCase",
  description:
    "There are many emotional and physical struggles related to a personal injury case. The lawyers at Negem & Worthington keep those struggles in mind during this time of need. We strive to reduce the stress associated with the emotional and physical...",
  formTopPosition: true,
  contentTitle: "Local Litigators with a Personal Reputation",
  content: [
    "There are many emotional and physical struggles related to a personal injury case. The lawyers at Negem & Worthington keep those struggles in mind during this time of need. We strive to reduce the stress associated with the emotional and physical concerns in the wake of a personal injury.",
    "Superior communication is extremely important when providing superior personal service. We invite you to learn more about the attorneys at Negem & Worthington. We don’t just provide a set of legal skills, we provide dedication and knowledge from a team who cares about you. ",
  ],
};
