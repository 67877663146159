import React from 'react';
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createStore } from "redux";
import { BrowserRouter } from "react-router-dom";
import reducers from "./reducers";
import App from "./App/App";
import "./sass/app.scss";
import ScrollToTop from "./App/components/ScrollToTop";

const rootContainer = document.querySelector("#root");

const mainApp = (
  <Provider store={createStore(reducers)}>
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </Provider>
);
if (rootContainer.hasChildNodes()) {
  ReactDOM.hydrateRoot(rootContainer, mainApp);
} else {
  ReactDOM.createRoot(rootContainer).render(mainApp);
}


