import React, { useRef, useEffect, Fragment } from "react";
import { useLocation } from "react-router-dom";
import PageDataController from "../site-data/components/PageDataController";
import FormSelection from "../site-data/components/Forms";
import ContentPageContent from "../components/ContentPageContent";
import AttorneyPageContent from "../components/AttorneyPageContent";
import ClientProcessComp from "../components/ClientProcessComp";
import Videos from "../components/Videos";

const SectionContentPage = () => {
  const path = useLocation().pathname.split("/");
  const [type, slug] = path.legth > 2 ? [path[1], null] : [path[1], path[2]];

  const scrollRefs = useRef([]);
  const data = new PageDataController(type).returnContentPage(slug);
  const form = new FormSelection(data.form).returnSelection();

  if (data.size) console.log("data", data, type, slug);

  useEffect(() => {
    if (data.title) {
      document.title = "N&W | " + data.title;
    }
  }, [data]);
  const handleSectionClick = (index) => () => {
    scrollRefs.current[index].scrollIntoView({ block: "center", inline: "center", behavior: "smooth", alignToTop: false });
  };

  return (
    <div className="bg-lightGrey" id="overview">
      <div className="" style={{ background: `url(${data.heroImage ? data.heroImage : data.image})  ${ data.customImageAlignment !== undefined ? data.customImageAlignment : "0% " + data.imageYAlign + "% "}  /cover` }}>
        <div>
          <div
            className="container-xl text-white ps-4 ps-xl-5 ps-xxl-0 heroText section-content-header"
            style={{
              paddingBottom: "1rem",
              fontFamily: "PT Serif Caption,serif",
              zIndex: "1",
              textShadow: "3px 3px 12px rgba(0,0,0,0.3)",
            }}
          >
            {data.title}
          </div>

          {data.topLinks ? (
            <div
              className="text-white container-fluid w-100 d-none d-md-block"
              style={{ height: "5.5rem", backgroundColor: "rgba(0,0,0,0.4)", backgroundBlendMode: "multiply" }}
            >
              <div className="container-xl d-flex justify-center pt-4">
                {data.topLinks.map((el, i) => {
                  return (
                    <a
                      key={i}
                      className="text-center flex-fill  text-white fw-light btn btn-link"
                      style={{ textDecoration: "none" }}
                      onClick={handleSectionClick(i)}
                      href={`#${el.sectionLink === "meet" ? el.sectionTitle + " " + data.title.split(" ")[0] : el.sectionLink}`}
                    >
                      {el.sectionLink === "meet" ? el.sectionTitle + " " + data.title.split(" ")[0] : el.sectionTitle}
                    </a>
                  );
                })}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      {type === "attorneys" ? <AttorneyPageContent data={data} form={form} scrollRefs={scrollRefs} /> : <Fragment />}

      {type === "practice-areas" || type === "privacy-policy" ? (
        <ContentPageContent data={data} form={form} scrollRefs={scrollRefs} />
      ) : (
        <Fragment />
      )}

      {type === "videos" ? <Videos form={form} /> : <Fragment />}

      {data.showProcess ? (
        <div className="bg-lightGrey" ref={(el) => (scrollRefs.current[3] = el)}>
          <ClientProcessComp />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default SectionContentPage;
