import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PageDataController from "../site-data/components/PageDataController";
import FormContainer from "../components/ContactForms/FormContainer";
import FormSelection from "../site-data/components/Forms";
import Tiles from "../components/Tiles";
import SmallWidthTiles from "../components/SmallWidthTiles";

const SectionContentPage = () => {
  const location = useLocation();
  const path = location.pathname.split("/");
  let slug = "";
  while (slug === "") {
    slug = path.pop();
  }

  let [data] = new PageDataController(slug).returnTitlePage();

  const form = new FormSelection(data.form).returnSelection();
  const tiles = new PageDataController().tileData(slug);

  useEffect(() => {
    if (data.title) {
      document.title = "N&W | " + data.title;
    }
  }, [data]);
  return (
      <div className="bg-lightGrey ">
          <div
              style={{background: `url(${data.heroImage ? data.heroImage : data.image})  ${data.imageAlignment ? data.imageAlignment : "50% " + data.imageYAlign + "% "} /cover`}}>
              <div>
                  <div
                      className="container-xl text-white ps-4 ps-xl-5 ps-xxl-0 heroText section-content-header"
                      style={{
                          paddingBottom: "1rem",
                          fontFamily: "PT Serif Caption,serif",
                          zIndex: "1",
                          textShadow: "3px 3px 12px rgba(0,0,0,0.3)",
                      }}
                  >
                      {data.title}
                  </div>
              </div>
          </div>

          <div className="container-xl bg-lightGrey mb-lg-5" style={{zIndex: "9999"}}>
              <div className="row" style={{minHeight: "500px"}}>
                  <div className="col-lg-7 col-xl-8 pt-5 p-sm-5 px-4 ">
                      {data.contentTitle ? <p className="fw-light h3 pb-3">{data.contentTitle}</p> : ""}

                      {data.content.map((el, i) => (
                          <p key={i} className="fw-light">
                              {el}
                          </p>
                      ))}
                      {data.smallTiles ? (
                          <SmallWidthTiles tiles={tiles}/>
                      ) : ("")}


                  </div>
                  <div className="col-lg-5 col-xl-4  order-first order-lg-last position-relative">
                      <div className="mt-3 title-form  ">
                          <FormContainer form={form}/>
                      </div>
                  </div>
              </div>
          </div>
          <div className="px-sm-4 pb-2 bg-lightGrey">
              {!data.smallTiles ? (

                  <div className="mb-5 ">
                      <Tiles tiles={tiles}/>
                  </div>
                  
                  ) : ("")}
          </div>
          </div>
          );
          };

export default SectionContentPage;
