import link from "../links";

const joeWorthington = {
  slug: "joe-worthington",
  link: "/attorneys/joe-worthington",
  image: "/img/2024/Worthington_Center-Bust.jpg",
  heroImage: "/img/2024/Worthington_web.jpg",
  imgAlt: "Joe Worthington in a nice suit with a smile",
  imageYAlign: "40",
  customImageAlignment: " top center ",
  title: "Joe Worthington",
  form: "DiscussYourCase",
  showSlider: true,
  showProcess: true,
  showForm: true,
  description:
    " Having practiced law in Texas for over twenty years, Joe Worthington brings a wealth of knowledge and experience to each personal injury case he takes on, having handled cases involving everything from trucking accidents to oilfield injuries ...",
  content: [
    <div className="row overview mb-5">
      <div className="h2 fw-light mb-4">Meet Joe</div>
      <p className="fw-light mt-2">
        Having practiced law in Texas for over twenty years, Joe Worthington brings a wealth of knowledge and experience to each personal
        injury case he takes on, having handled cases involving everything from trucking accidents to oilfield injuries. Originally from
        Seminole, Joe moved to Tyler in 1985 and served as Assistant District Attorney for Smith County for two years. He then decided to
        stay in Tyler and practice law locally, joining the Negem Firm in 2003.
      </p>
      <p className="fw-light mt-2">
        Joe earned both his undergraduate degree from {link("Texas Tech University")} and his law degree from{" "}
        {link("Texas Tech University School of Law")}, where he belonged to the Delta Theta Phi Law Fraternity. Today, as an active member
        of the {link("State Bar of Texas")} and the {link("Smith County Bar Association")}, Joe continues to stay abreast of new
        developments in personal injury law. His extensive trial experience includes regularly representing clients in{" "}
        {link("United States District Court, Eastern District of Texas")}.
      </p>
      <p className="fw-light mt-2">
        Joe works tirelessly to ensure that personal injury victims receive just compensation, and is proud that his efforts as a
        plaintiff’s attorney have helped make East Texas a safer and healthier place to live and work. Joe resides in Tyler with his wife
        Amy and their children. They attend Pollard United Methodist Church.
      </p>
    </div>,

    <div className="row experience mb-5">
      <div className="h3 fw-light mb-2">Education</div>
      <div className="ms-md-4 mt-2 fw-light">
        <ul className="mb-4 list">
          <li>{link("Texas Tech University School of Law")}, Doctorate of Jurisprudence, (1985)</li>
          <li>{link("Texas Tech University")}, Bachelors of Science, (1982)</li>
        </ul>
      </div>
    </div>,

    <div className="row admissions mb-5">
      <div className="h3 fw-light mb-2">Admissions</div>
      <div className="ms-md-4 mt-2 fw-light">
        <ul className="mb-4 list">
          <li>{link("State Bar of Texas")}</li>
          <li>{link("United States District Court, Eastern District of Texas")}</li>
        </ul>
      </div>
    </div>,

    <div className="row professional mb-5">
      <div className="h3 fw-light mb-2">Professional Affiliations</div>
      <div className="ms-md-4 mt-2 fw-light">
        <ul className="mb-4 list">
          <li>{link("State Bar of Texas")}</li>
          <li>{link("Smith County Bar Association")}</li>
        </ul>
      </div>
    </div>,
  ],
};

export default joeWorthington;
